<template>
    <div class="clothinginventory content-index" v-loading="loading">
        <el-row class="button">
            <el-page-header
                    style="width: 100%;padding:10px"
                    @back="$router.push('/home/more')"
                    content="衣物盘点"
            ></el-page-header>
            <el-button type="primary" size="medium" @click="goInventoryOperation"
            >我要扫码
            </el-button>
        </el-row>
        <div class="table-box">
            <Table
                    :serialNumber="true"
                    :button-type="true"
                    :current-value="formData.current"
                    :header-style="headerStyle"
                    :orientation="'center'"
                    :table-data="tableData"
                    :title-list="titleListData"
                    :total="total"
                    :buttonWidth="200"
                    @handleCurrentChange="handleCurrentChange"
                    @operationClick="operationClick"
            />
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2";
import {delFastCheck, getFastCheck} from "@/api/clothes/check";

export default {
    name: "fastCheck",
    components: {
        Table,
    },
    data() {
        return {
            total: 0,
            formData: {
                current: 1,
                size: 10,
            },
            headerStyle: {
                fontSize: "14px",
                color: "black",
            },
            titleListData: [
                {
                    prop: "checkId",
                    label: "盘点单号",
                },
                {
                    prop: "checkStatusDesc",
                    label: "任务状态",
                },
                {
                    prop: "clothesCount",
                    label: "衣物数量",
                },
                {
                    prop: "accessoryCount",
                    label: "附件数量",
                },
                {
                    prop: "clothesStatusAnomalyNum",
                    label: "状态异常数量",
                },
                {
                    prop: "storeBelongAnomalyNum",
                    label: "门店归属异常数量",
                },
                {
                    prop: "createdTime",
                    label: "盘点时间",
                }
            ],
            tableData: [],
            loading: false,
            buttonDisabled: false,
        };
    },
    created() {
        this.getClothesCheckData();
    },
    methods: {
        // 获取衣物盘点的数据
        getClothesCheckData() {
            this.loading = true;
            getFastCheck({...this.formData})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.total = data.data.total;
                        this.buttonDisabled = data.data.records?.some(
                            (item) => item.checkStatusDesc == "进行中"
                        );
                        this.tableData = data.data.records?.map((item) => {
                            if (item.checkStatusDesc == "进行中") {
                                item.buttonList = [
                                    {
                                        name: "继续盘点",
                                        size: "mini",
                                        type: "primary",
                                        code: "check",
                                    },
                                    {
                                        name: "删除",
                                        size: "mini",
                                        type: "danger",
                                        code: "del",
                                    },
                                ];
                            }
                            if (item.checkStatusDesc == "已完成") {
                                item.buttonList = [
                                    {
                                        name: "详情",
                                        size: "mini",
                                        type: "primary",
                                        code: "details",
                                    },
                                ];
                            }
                            return item;
                        });
                        this.loading = false;
                    } else {
                        this.tableData = [];
                        this.loading = false;
                    }
                })
                .catch(() => {
                    this.tableData = [];
                    this.loading = false;
                });
        },
        // 改变页码触发
        handleCurrentChange(val) {
            this.formData.current = val;
            this.getClothesCheckData();
        },
        operationClick({row, code}) {
            if (code == "check") {
                this.$router.push({
                    path: "/home/more/scanCode",
                    query: {
                        checkId: row.checkId,
                    },
                });
            }
            if (code == "details") {
                this.$router.push({
                    path: "/home/more/fastCheckDetail",
                    query: {
                        checkId: row.checkId,
                    },
                });
            }
            if (code == "del") {
                this.$confirm("此操作将永久删除该盘点信息, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        delFastCheck(row.checkId).then(({ data }) => {
                            if (data.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.getClothesCheckData()
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            }
        },
        goInventoryOperation() {
            if (this.buttonDisabled) {
                this.$message.error("存在未完成的衣物盘点，请先完成盘点!");
                return;
            }
            this.$router.push({
                path: "/home/more/scanCode",
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.clothinginventory {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  .button {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .table-box {
    height: calc(100vh - 220px);
  }
}
</style>
